<template>
  <div>
<div style="text-align: right;">
        <el-button type="primary" class="mb10" size="mini" @click="exportExcel">导出数据</el-button>
      </div>
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="录入时间" prop="create_time" />
            <ElTableColumn label="顾客姓名" prop="customer_name" />
            <ElTableColumn label="联系方式" prop="telephone" />
            <ElTableColumn label="来源" prop="settingname" />
            <ElTableColumn label="进店时间" prop="first_time" />
            <ElTableColumn label="成交金额" prop="cj_money" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import pagination from '@/components/Pagination'
import { shopDkDetail } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  staffName = ''; // 员工名称
  statistics = 0; // 统计范围
}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      loading:false,
    }
  },

  mounted () {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      shopDkDetail({...this.page,shop_id:this.$route.query.shop_id,source_id:this.$route.query.source_id,export:0,start_time:this.$route.query.start_time,end_time:this.$route.query.end_time}).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.loading = false
      });
    },
    // 导出
    exportExcel() {
      shopDkDetail({shop_id:this.$route.query.shop_id,source_id:this.$route.query.source_id,export:1}).then(res => {
        window.open(res.data.url);
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
